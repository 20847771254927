/* eslint-disable no-console */
/* eslint-disable no-undef */
import { registerApplication, start } from "single-spa";

const env = process.env.NODE_ENV;
const isLocal = window.location.hostname === "localhost";

const getLoginURL = () => {
  if (isLocal) {
    return "http://localhost:8090/bundle.js";
  }
  return env !== "production"
    ? "https://login.micro.dev.gad24.tools/bundle.js"
    : "https://login.micro.gad24.tools/bundle.js";
};

const getDDURL = () => {
  if (isLocal) {
    return "http://localhost:8095/bundle.js";
  }
  return env !== "production"
    ? "https://dd.micro.dev.gad24.tools/bundle.js"
    : "https://dd.micro.gad24.tools/bundle.js";
};

const getMainURL = () => {
  if (isLocal) {
    return "http://localhost:8093/bundle.js";
  }
  return env !== "production"
    ? "https://main-front.micro.dev.gad24.tools/bundle.js"
    : "https://main-front.micro.gad24.tools/bundle.js";
};

const getContentURL = () => {
  if (isLocal) {
    return "http://localhost:8091/bundle.js";
  }
  return env !== "production"
    ? "https://frontv1.micro.dev.gad24.tools/bundle.js"
    : "https://frontv1.micro.gad24.tools/bundle.js";
};

const getOrderURL = () => {
  if (isLocal) {
    return "http://localhost:8092/bundle.js";
  }
  return env !== "production"
    ? "https://dev.gad24.tools/micro/order/bundle.js"
    : "https://order.micro.gad24.tools/bundle.js";
};

function getContentElement() {
  return document.getElementById("content-front");
}

registerApplication({
  name: "order",
  app: () => System.import(getOrderURL()),
  activeWhen: [(location) => location.pathname.startsWith("/v2/orders")],
  customProps: {
    domElementGetter: getContentElement,
  },
});

registerApplication({
  name: "login",
  app: () => System.import(getLoginURL()),
  activeWhen: [
    (location) =>
      location.pathname.startsWith("/login") ||
      location.pathname.startsWith("/gad-art") ||
      location.pathname.startsWith("/backdrops") ||
      location.pathname.startsWith("/registration") ||
      location.pathname.startsWith("/v2") ||
      location.pathname.startsWith("/reset-password"),
  ],
});

registerApplication({
  name: "main",
  app: () => System.import(getMainURL()),
  activeWhen: [
    (location) =>
      !(
        location.pathname.startsWith("/login") ||
        location.pathname.startsWith("/registration") ||
        location.pathname.startsWith("/reset-password")
      ),
  ],
});

registerApplication({
  name: "damage-detection",
  app: () => System.import(getDDURL()),
  activeWhen: [
    (location) =>
      !(
        location.pathname.startsWith("/login") ||
        location.pathname.startsWith("/gad-art") ||
        location.pathname.startsWith("/backdrops") ||
        location.pathname.startsWith("/registration") ||
        location.pathname.startsWith("/v2") ||
        location.pathname.startsWith("/reset-password")
      ) && location.pathname.startsWith("/damage-detections"),
  ],
  customProps: {
    domElementGetter: getContentElement,
  },
});

registerApplication({
  name: "content",
  app: () => System.import(getContentURL()),
  activeWhen: [
    (location) =>
      !(
        location.pathname.startsWith("/login") ||
        location.pathname.startsWith("/gad-art") ||
        location.pathname.startsWith("/backdrops") ||
        location.pathname.startsWith("/registration") ||
        location.pathname.startsWith("/reset-password") ||
        location.pathname.startsWith("/v2") ||
        location.pathname.startsWith("/damage-detections")
      ),
  ],
  customProps: {
    domElementGetter: getContentElement,
  },
});

start({
  urlRerouteOnly: true,
});
